<template>
  <footer
    class="bg-gray-600 rounded-t-30 text-white py-30 md:pt-60 md:pb-40 lg:pt-80 bg-bottom bg-cover"
    :style="footerBackground"
  >
    <div class="container">
      <h2 class="heading--headline footer__subscribe-heading">
        Subscribe for Good Sports news and updates
      </h2>
      <FooterSignupForm />

      <div class="footer__acknowledgement-and-diversity">
        <div class="footer__acknowledgement-and-diversity-background"></div>
        <div class="footer__acknowledgement">
          <p class="footer__acknowledgement-title">{{ acknowledgementHeader }}</p>
          <img
            src="@/assets/images/traditional-custodians-flag.jpg"
            alt="traditional-custodians-flag"
            class="footer__acknowledgement-and-diversity-flag"
            width="100"
            height="64"
            loading="lazy"
          />
          <img
            src="@/assets/images/torres-strait-islander-flag.png"
            alt="Torres Strait Islander flag"
            class="footer__acknowledgement-and-diversity-flag"
            width="100"
            height="64"
            loading="lazy"
          />
          <div class="footer__acknowledgement-description" v-html="acknowledgementText" />
        </div>
        <div class="footer__diversity-statement">
          <p class="footer__diversity-statement-title">{{ diversityHeader }}</p>
          <img
            src="@/assets/images/diversity-flag.webp"
            alt="diversity-inclusion-flag"
            class="footer__acknowledgement-and-diversity-flag"
            width="100"
            height="64"
            loading="lazy"
          />
          <div class="footer__diversity-statement-description" v-html="diversityText" />
        </div>
      </div>
    </div>

    <div class="container-wide">
      <div class="column-row-group mt-40 mb-30 md:mb-80 lg:mb-50">
        <div class="column-row md:w-7/12 flex justify-center md:justify-start mb-20 md:mb-0">
          <LogoFooter class="footer__logo" />
        </div>
        <div class="column-row md:w-5/12 flex justify-around md:justify-end md:items-end">
          <a
            :href="facebookLink"
            target="_blank"
            aria-label="Go to Facebook"
            title="Go to Facebook"
            class="footer__social-link"
          >
            <IconFacebook class="h-24 w-24 text-gray-600" />
          </a>
          <a
            :href="instagramLink"
            target="_blank"
            aria-label="Go to Instagram"
            title="Go to Instagram"
            class="footer__social-link"
          >
            <IconInsta class="h-24 w-24 text-gray-600" />
          </a>
          <a
            :href="youtubeLink"
            target="_blank"
            aria-label="Go to Youtube"
            title="Go to Youtube"
            class="footer__social-link"
          >
            <IconYoutube class="h-24 w-24 text-gray-600" />
          </a>
        </div>
      </div>
      <div class="column-row-group">
        <div class="column-row md:w-6/12 footer__contact-column">
          <p class="footer__contact-line mb-20 lg:mb-0 lg:mr-40 whitespace-no-wrap">
            Phone
            <a :href="`tel:${contactNumber}`" class="text-link--light">{{ contactNumber }}</a>
          </p>
          <p class="footer__contact-line">
            <a :href="primaryLink" class="text-link--light">{{ primaryLinkText }}</a>
          </p>
        </div>
        <div class="column-row md:w-6/12">
          <div class="flex flex-wrap justify-between md:justify-end md:items-end -m-15">
            <a
              v-for="(link, index) in secondaryLinks"
              :key="index"
              :href="link.url"
              class="text-link--light font-walsheim text-15 whitespace-no-wrap m-15"
            >
              {{ link.text }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import FooterSignupForm from '@/components/global/FooterSignupForm.vue';
import IconFacebook from '@/components/svg/IconFacebook.vue';
import IconInsta from '@/components/svg/IconInsta.vue';
import IconYoutube from '@/components/svg/IconYoutube.vue';
import LogoFooter from '@/components/svg/LogoFooter.vue';

export default {
  name: 'SiteFooter',
  components: {
    FooterSignupForm,
    IconFacebook,
    IconInsta,
    IconYoutube,
    LogoFooter
  },
  props: {
    backgroundImage: String,
    contactNumber: String,
    facebookLink: String,
    instagramLink: String,
    youtubeLink: String,
    primaryLink: String,
    primaryLinkText: String,
    secondaryLinks: Array,
    acknowledgementHeader: String,
    acknowledgementText: String,
    diversityHeader: String,
    diversityText: String
  },
  computed: {
    footerBackground() {
      if (!this.backgroundImage) return {};
      return {
        backgroundImage: `url('${this.backgroundImage}')`
      };
    }
  }
};
</script>

<style>
.footer__subscribe-heading {
  @apply text-center mb-20 mx-auto;

  @screen md {
    @apply w-10/12;
  }

  @screen xl {
    @apply text-left w-8/12 mx-0;
  }
}

.footer__form-container {
  @apply mb-50;

  @screen md {
    @apply mb-80;
  }

  @screen lg {
    margin-bottom: 5.625rem;
  }

  @screen xl {
    margin-bottom: 8.125rem;
  }
}

.footer__acknowledgement-and-diversity {
  @apply border-t border-b relative overflow-hidden font-walsheim;
  @apply mt-30 py-50 px-24 text-center;
  @screen md {
    @apply mt-80 px-60 flex justify-between;
  }
  @screen lg {
    @apply mt-50 py-40 px-128;
  }

  & .footer__acknowledgement-and-diversity-background {
    @apply absolute w-full h-full left-0 top-0;
    background-color: black;
    opacity: 0.5;
    z-index: 1;
  }

  & .footer__acknowledgement,
  & .footer__diversity-statement {
    @apply relative;
    z-index: 2;
    @screen md {
      @apply w-5/12;
    }
  }

  & .footer__acknowledgement-title,
  & .footer__diversity-statement-title {
    @apply text-20;
    &.footer__diversity-statement-title {
      @apply mt-50;
      @screen md {
        @apply mt-0;
      }
    }
  }

  & .footer__acknowledgement-and-diversity-flag {
    @apply mx-8 my-24 inline-block;
    @screen lg {
      @apply mx-16 my-30;
    }
  }
}

.footer__logo {
  width: 10.25rem;

  @screen lg {
    width: 11.75rem;
  }
}

.footer__social-link {
  @apply bg-white rounded-full w-50 h-50 flex justify-center items-center flex-shrink-0;
  @apply transition-colors duration-100 ease-in;

  &:hover {
    @apply bg-blue-dark;

    & svg {
      @apply text-white transition-colors duration-100 ease-in;
    }
  }

  @screen md {
    @apply ml-30;
  }
}

.footer__contact-column {
  @apply flex flex-col;
  margin-bottom: 70px;

  @screen md {
    @apply mb-0;
  }

  @screen lg {
    @apply flex-row;
  }
}

.footer__contact-line {
  @apply font-bold text-19 text-center whitespace-no-wrap;

  @screen md {
    @apply text-left;
  }
}
</style>
